// src/resources/events/EventCreateEdit.js
import React, { useState } from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  required,
  useRecordContext,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Grid, Box, Typography, Paper } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocationSelect from '../../components/locationSelectField';
import { EventPreviewCard, EventPreviewThread } from '../../components/eventPreviews';

const eventCategories = [
  { id: 'music', name: 'Music' },
  { id: 'festival', name: 'Festival' },
  { id: 'comedy', name: 'Comedy' },
  { id: 'art', name: 'Art' },
  { id: 'sports', name: 'Sports' },
  // Add other categories as defined in your Hasura enum
];

const CustomToolbar = props => (
  <Toolbar
    {...props}
    sx={{
      background: 'none',
      padding: '20px',
      marginLeft: '80px',
      marginBottom: '20px',
    }}
  >
    <SaveButton
      label="Save Event"
      icon={<EventAvailableIcon />}
      sx={{
        backgroundColor: 'primary.main',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'primary.dark',
        },
        color: 'white',
        padding: '12px 70px',
      }}
    />
  </Toolbar>
);

const EventForm = ({ mode, ...props }) => {
  const record = useRecordContext();
  const [locationName, setLocationName] = useState('');

  const handleLocationNameChange = (newLocationName) => {
    setLocationName(newLocationName);
  };

  return (
    <SimpleForm
      {...props}
      toolbar={<CustomToolbar />}
      defaultValues={record}
    >
      <Grid container spacing={3} >
        {/* Left Column: Form Fields */}
        <Grid item xs={12} md={6} mr={10} ml={10}>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
              Event Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  source="event_name"
                  label="Event Name"
                  multiline
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{marginTop: 1}}>
                <LocationSelect
                  source="location_id"
                  label="Location"
                  validate={required()}
                  defaultLocation={record?.location?.location_id}
                  onLocationChange={handleLocationNameChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} >
                <SelectInput
                  source="event_category"
                  label="Event Category"
                  choices={eventCategories}
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="external_link" label="External Link" multiline fullWidth />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ paddingBottom: 3 }}>
              Start & End Date
            </Typography>
            <ArrayInput source="occurrences" label={false}>
              <SimpleFormIterator>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DateTimeInput
                      source="start_date"
                      label="Start Date"
                      validate={required()}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateTimeInput
                      source="end_date"
                      label="End Date"
                      validate={required()}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </SimpleFormIterator>
            </ArrayInput>
          </Box>

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
              Image
            </Typography>
            <ImageInput source="event_image" label="Event Image" accept="image/*" fullWidth>
              <ImageField source="src" title="title" />
            </ImageInput>
          </Box>
        </Grid>

        {/* Right Column: Preview Component */}
        <Grid item xs={12} md={4} >
          <EventFormPreview record={record} locationName={locationName} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const EventFormPreview = ({ record, locationName }) => {
    const { control } = useFormContext();
    const eventName = useWatch({ control, name: 'event_name' });
    const eventImage = useWatch({ control, name: 'event_image' });
    const startDate = useWatch({ control, name: 'occurrences[0].start_date' });
  
    const imageUrl = eventImage
    ? eventImage
    : record?.event_image_url || 'placeholder-image-url.jpg';
  
    return (
      <Box sx={{ width: '500px', flexShrink: 0}}>
        <Paper elevation={3} sx={{ p: 2, position: 'sticky', top: 20, paddingLeft: 4 }}>
          <Typography variant="h6" gutterBottom >
            Preview
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom sx={{paddingTop: 2}}>
              Card View
            </Typography>
            <EventPreviewCard
              eventName={eventName}
              eventImage={imageUrl}
              startDate={startDate}
              locationName={locationName}
            />
            <Typography variant="subtitle1" gutterBottom sx={{paddingTop: 4}}>
              Thread View
            </Typography>
            <EventPreviewThread
              eventName={eventName}
              eventImage={imageUrl}
              startDate={startDate}
              locationName={locationName}
            />
          </Box>
        </Paper>
      </Box>
    );
  };

export const EventCreate = (props) => (
  <Create {...props} redirect="/events">
    <EventForm mode="create" />
  </Create>
);

export const EventEdit = (props) => (
  <Edit {...props} redirect="/events">
    <EventForm mode="edit" />
  </Edit>
);