import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import calendarIcon from '../assets/icons/calendar.png';
import locationIcon from '../assets/icons/location.png';
import messageIcon from '../assets/icons/messages.png';
import peopleIcon from '../assets/icons/users.png';
import bookmarkIcon from '../assets/icons/bookmark.png';
import buttonsSection from '../assets/icons/button-section.png';
import backButton from '../assets/icons/backbutton.png';
import statusBar from '../assets/icons/status-bar.png';
import { format } from 'date-fns';

const EventPreviewCardComponent = ({ eventName, eventImage, startDate, locationName }) => {
  const formattedDate = startDate ? format(new Date(startDate), 'E, MMM d') : 'Start Date';

  return (
    <Card sx={{ width: 400, height: 210, position: 'relative', overflow: 'hidden' }}>
      <CardMedia
        component="img"
        height="210"
        image={eventImage?.src || eventImage }
        sx={{
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 14,
          left: 14,
        }}
      >
        <img src={bookmarkIcon} alt="Bookmark" />
      </Box>
      <CardContent
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 82,
          padding: '10px 12px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: 20,
              padding: '8px 10px',
              marginRight: 1,
            }}
          >
            <img src={messageIcon} alt="Message" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: 20,
              padding: '8px 10px',
            }}
          >
            <img src={peopleIcon} alt="People" />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            padding: '8px 12px',
            width: '70%',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
            {eventName || 'Event Name'}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={calendarIcon} alt="Calendar" style={{ marginRight: 4 }} />
            <Typography variant="caption" sx={{ marginRight: 2 }}>
              {formattedDate || 'Start Date'}
            </Typography>
            <img src={locationIcon} alt="Location" style={{ marginRight: 4 }} />
            <Typography variant="caption">
              {locationName || 'Event Location'}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const EventPreviewCard = React.memo(EventPreviewCardComponent);

const EventPreviewThreadComponent = ({ eventName, eventImage, startDate, locationName }) => {
  const formattedDate = startDate ? format(new Date(startDate), 'E, MMM d') : 'Start Date';

  return (
    <Card sx={{ width: 420, height: 300, position: 'relative', overflow: 'hidden' }}>
      <CardMedia
        component="img"
        height="300"
        image={eventImage?.src || eventImage}
        sx={{
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
            <Box
        sx={{
          position: 'absolute',
          alignSelf: 'center',
          top: 10,
          left: 30
          
        }}
      >
        <img src={statusBar} alt="StatusBar" style={{ width: 360 }}/>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 70,
          right: 38,
        }}
      >
        <img src={bookmarkIcon} alt="Bookmark" />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 70,
          left: 25,
        }}
      >
        <img src={backButton} alt="Back" style={{ width: 14}} />
      </Box>
      <CardContent
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 215,
          padding: '10px 12px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            padding: '8px 12px',
            width: '55%',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0 }}>
            {eventName || 'Event Name'}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={calendarIcon} alt="Calendar" style={{ marginRight: 4 }} />
            <Typography variant="caption" sx={{ 
              marginRight: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' 
              }}>
              {formattedDate || 'Start Date'}
            </Typography>
            <img src={locationIcon} alt="Location" style={{ marginRight: 4 }} />
            <Typography variant="caption" style={{
              marginRight: 4,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' 
            }}>
              {locationName || 'Event Location'}
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              right: 10,
              bottom: 6,
            }}
          >
            <img src={buttonsSection} alt="buttonsSection" style={{ width: 175 }} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const EventPreviewThread = React.memo(EventPreviewThreadComponent);
