// src/components/locationSelectField.js
import React, { useEffect, useState } from 'react';
import { useDataProvider, useInput, useRecordContext } from 'react-admin';
import { Select, MenuItem, FormControl, InputLabel, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const LocationSelect = ({onLocationChange, defaultLocation, ...props}) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  
  const {
    field: { name, onChange, value },
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted }
  } = useInput(props);

  const [locations, setLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
  const [newLatitude, setNewLatitude] = useState('');
  const [newLongitude, setNewLongitude] = useState('');

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const { data } = await dataProvider.getList('locations', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' }
        });

        setLocations(data);

        onChange(defaultLocation);
        console.log('defaultLocation', defaultLocation);

      } catch (e) {
        console.error('Error fetching locations:', e);
      } 
    };
    
    fetchLocations();
  }, [dataProvider, record]);

  const handleAddLocation = async () => {
    try {
      const { data } = await dataProvider.create('locations', {
        data: {
          name: newLocationName,
          latitude: parseFloat(newLatitude),
          longitude: parseFloat(newLongitude)
        }
      });
  
      const newLocation = {
        ...data,
        id: data.id,
      };
  
      setLocations(prevLocations => [...prevLocations, newLocation]);
      onChange(data.id);
      setOpen(false);
      setNewLocationName('');
      setNewLatitude('');
      setNewLongitude('');
    } catch (e) {
      console.error('Error creating location:', e);
    }
  };


  const handleChange = (event) => {
    const selectedLocationId = event.target.value;
    onChange(selectedLocationId);

    // Find the selected location object
    const selectedLocation = locations.find(loc => loc.id === selectedLocationId);
    if (selectedLocation) {
      onLocationChange(selectedLocation.location_name); 
    }
  };

  return (
    <FormControl fullWidth error={isTouched && invalid}>
      <InputLabel id="location-label">Location</InputLabel>
      <Select
        labelId="location-label"
        id="location-select"
        value={value || ''}
        label="Location"
        onChange={handleChange}
      >
        {locations.map(loc => (
          <MenuItem key={loc.id} value={loc.id}>
            {loc.location_name}
          </MenuItem>
        ))}
        <MenuItem onClick={() => setOpen(true)} sx={{ color: 'blue' }}>
          <ListItemIcon>
            <AddIcon sx={{ color: 'blue' }} />
          </ListItemIcon>
          <ListItemText primary="Add new location" />
        </MenuItem>
      </Select>
      {isTouched && error && <span style={{ color: 'red' }}>{error}</span>}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Location</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Location Name"
            fullWidth
            value={newLocationName}
            onChange={(e) => setNewLocationName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Latitude"
            fullWidth
            value={newLatitude}
            onChange={(e) => setNewLatitude(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Longitude"
            fullWidth
            value={newLongitude}
            onChange={(e) => setNewLongitude(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleAddLocation}>Add</Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};

export default LocationSelect;