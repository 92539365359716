// src/admin/resources/events/eventList.js
import * as React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, UrlField } from 'react-admin';
import { CustomOccurrencesField } from '../../components/customFields';

export const EventList = (props) => (
  <List {...props}>
    <Datagrid
      sx={{
        minWidth: '100%', 
        maxWidth: 'calc(100vh - 2em)',
        backgroundColor: 'white',
        // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        // borderRadius: '1em',
        overflow: 'auto',
        '& .RaDatagrid-headerCell': { 
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          backgroundColor: '#f4f4f4',
        },
        '& .column-event_id, & .column-event_name, & .column-occurrences, & .column-event_category': { 
          minWidth: '20em',
        },
        '& .column-event_category': { 
          minWidth: '15em',
        },
        '& .column-event_image_url, & .column-external_link, & .column-location\\.location_name': {
          maxWidth: '20em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& .RaDatagrid-headerCell.column-EditButton, & .RaDatagrid-headerCell.column-DeleteButton': {
          minWidth: '10em',
        },

        
      }}
    >
      <EditButton basePath="/events" />
      <TextField source="event_name" label="Event Name" />
      <TextField source="event_category" label="Category" />
      <TextField source="location.location_name" label="Location" />
      <UrlField source="event_image_url" label="Event Image URL" />
      <UrlField source="external_link" label="External Link" />
      <CustomOccurrencesField source="occurrences" label="Date & Times" />
      <TextField source="event_id" label="ID" />
      <DeleteButton basePath="/events" />
    </Datagrid>
  </List>
);