// import { accessSecret } from './secretManager';
import { GraphQLClient } from 'graphql-request';
import { GET_EVENTS, CREATE_EVENT_MUTATION, DELETE_EVENT_MUTATION, GET_ONE_EVENT, UPDATE_EVENT_MUTATION, GET_LOCATIONS, CREATE_LOCATION, UPDATE_OCCURRENCES_MUTATION, GET_ONE_LOCATION} from './graphqlQueries';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import app from './firebase';

let graphQLClient;

const getGraphQLClient = async () => {
  const databaseUrl = process.env.REACT_APP_DATABASE_URL;
  const role = process.env.REACT_APP_HASURA_ROLE;
  const auth = getAuth();

  try {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user logged in");
      throw new Error("Authorization required");
    }

    let token = await user.getIdToken();
    const tokenResult = await user.getIdTokenResult();
    const nowInSeconds = Date.now() / 1000;

    if (tokenResult.expirationTime <= nowInSeconds + 300) {
      token = await user.getIdToken(true);
    }

    if (!graphQLClient) {
      graphQLClient = new GraphQLClient(databaseUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-hasura-role': `${role}`
        }
      });
    } else {
      graphQLClient.setHeaders({
        Authorization: `Bearer ${token}`,
          'x-hasura-role': `${role}`
      });
    }

  } catch (error) {
    console.error("Error initializing or refreshing the GraphQL client:", error);
    throw error;
  }

  return graphQLClient;
};

const storage = getStorage(app);

const uploadImageToStorage = async (file, basePath) => {

  const storageRef = ref(storage, basePath);
  await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(storageRef);

  return downloadURL;
};

const dataProvider = {
    getList: async (resource) => {
      try {
        const client = await getGraphQLClient();
        if (resource === "events") {
          const response = await client.request(GET_EVENTS);
          console.log(response);
          const data = response.events_events.map(event => ({
            ...event,
            id: event.event_id, // Map event_id to id for compatibility
            location: {
              ...event.location,
              coordinates: event.location.location_point.coordinates
            },
            occurrences: event.occurrences.map(occ => ({
              ...occ,
              id: occ.occurrence_id
            }))
        }));
          return {
              data: data,
              total: data.length,
          };
        }
        if (resource === "locations") {
          const response = await client.request(GET_LOCATIONS);
          const data = response.events_event_locations.map(location => ({
            ...location,
            id: location.location_id, 
          }));
          return {
            data: data,
            total: data.length,
          };
        }
      } catch (error) {
        console.error("Error while fetching data for resource:", resource, "; Error:", error);
        throw new Error(`Error fetching data for resource ${resource}: ${error.message || error}`);
      }
    },
    getOne: async (resource, params) => {
      try {
        if (resource === "events") {
          const { id } = params;
          const client = await getGraphQLClient();
          const response = await client.request(GET_ONE_EVENT, { event_id: id });

          const eventData = response.events_events_by_pk;

          const locationPoint = eventData.location.location_point || { coordinates: [0, 0] };
          const [longitude, latitude] = locationPoint.coordinates;

          const data = {
            ...eventData,
            id: eventData.event_id,
            latitude: latitude,
            longitude: longitude,
            locationName: eventData.location.location_name,
          };
  
          return { data: data };
        }
        if (resource === "event_locations") {
          const { id } = params;
          const client = await getGraphQLClient();
          const response = await client.request(GET_ONE_LOCATION, { location_id: id });
          const data = response.events_event_locations_by_pk;
          return { data: data };
        }
      } catch (error) {
        console.error("Failed to fetch event:", error);
        throw new Error("Error fetching event");
      }
    },
    getMany: () => {},
    update: async (resource, params) => {
      if (resource === "events") {
        const { data } = params;
    
        let eventImageUrl = data.event_image_url;
    
        if (data.event_image?.rawFile) {
          const eventImagePath = `event_images/${data.event_id}/event`;
          eventImageUrl = await uploadImageToStorage(data.event_image.rawFile, eventImagePath);
        }
    
        // Prepare the new event data for the main update
        const newEventData = {
          event_id: data.event_id,
          event_name: data.event_name,
          event_category: data.event_category,
          event_image_url: eventImageUrl,
          external_link: data.external_link,
          location_id: data.location_id,
        };

        // Prepare the new occurrences data
        const newOccurrencesData = {
          event_id: data.event_id,
          occurrences: data.occurrences.map(occ => ({
            event_id: data.event_id,
            start_date: occ.start_date,
            end_date: occ.end_date,
          }))
        };

        try {
          const client = await getGraphQLClient();

          // Step 1: Update main event data
          const eventResponse = await client.request(UPDATE_EVENT_MUTATION, newEventData);

          // Step 2: Update occurrences data
          const occurrencesResponse = await client.request(UPDATE_OCCURRENCES_MUTATION, newOccurrencesData);

          return { 
            data: {
              ...newEventData,
              id: eventResponse.update_events_events_by_pk.event_id,
            }
          };
        } catch (error) {
          console.error("Failed to update event:", error);
          throw new Error(`Some error was thrown. Check the console logs.`);
        }
      }
      throw new Error(`Unknown resource ${resource}`);
    },
    create: async (resource, params) => {
      try {
        const client = await getGraphQLClient();
        if (resource === "events") {
          const { data } = params;

          // might want to consider returning uuid generated by Hasura so it's managed by the database
          const eventId = uuidv4();
          
          const eventImagePath = `event_images/${eventId}/event`;
          
          // Upload images to Firebase and get URLs
          const eventImageUrl = await uploadImageToStorage(data.event_image.rawFile, eventImagePath);
  
          const newEventData = {
            event_id: eventId,
            event_name: data.event_name,
            event_category: data.event_category,
            event_image_url: eventImageUrl,
            external_link: data.external_link,
            location_id: data.location_id,
            occurrences: data.occurrences.map(occ => ({
              start_date: occ.start_date,
              end_date: occ.end_date
            }))
          };
  
          // Send the new event data to Hasura
          const response = await client.request(CREATE_EVENT_MUTATION, newEventData);
          return { data: { ...response.insert_events_events_one, id: response.insert_events_events_one.event_id } };
        }
        if (resource === "locations") {
          const { name, latitude, longitude } = params.data;
          const locationPoint = {
            type: 'Point',
            coordinates: [parseFloat(longitude), parseFloat(latitude)]
          };
        
          const newLocationData = {
            location_name: name,
            location_point: locationPoint
          };
        
          const response = await client.request(CREATE_LOCATION, newLocationData);
          return { data: { ...response.insert_events_event_locations_one, id: response.insert_events_event_locations_one.location_id } };
        }
      } catch (error) {
        console.error("Failed to create for resource:", resource, "; Error:", error);
        throw new Error(`Some error was thrown. Check the console logs.`);
      }
    },
    delete: async (resource, params) => {
      try {
        if (resource === "events") {
          const { id } = params;
          const client = await getGraphQLClient();
          const response = await client.request(DELETE_EVENT_MUTATION, { event_id: id });

          if (response.delete_events_events_by_pk) {
            const eventImagePath = `event_images/${id}/event`;
    
            const eventRef = ref(storage, eventImagePath);
            await deleteObject(eventRef).catch((error) => console.log(`Failed to delete event image: ${error.message}`));
    
            console.log(`Successfully deleted event and image for event ID: ${id}`);
          }

          return { data: response.delete_events_events_by_pk };
        }
      } catch (error) {
        console.error("Failed to delete event:", error);
        throw new Error("Error deleting event");
      }
    }
  };
  
  export default dataProvider;