import { gql } from 'graphql-request';

export const GET_EVENTS = gql`
  query GetEvents {
    events_events(order_by: {created_at: desc}) {
      event_id
      event_name
      event_category
      event_image_url
      external_link
      created_at
      location: event_location {
        location_id
        location_name
        location_point
      }
      occurrences: event_occurrences(order_by: {start_date: asc}) {
        occurrence_id
        start_date
        end_date
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GetLocations {
    events_event_locations(order_by: {location_name: asc}) {
      location_id
      location_name
      location_point
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocation($location_name: String!, $location_point: geography!) {
    insert_events_event_locations_one(object: {location_name: $location_name, location_point: $location_point}) {
      location_id
      location_name
      location_point
    }
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent(
    $event_id: uuid!,
    $event_name: String!,
    $event_category: events_event_categories_enum!,
    $event_image_url: String!,
    $external_link: String,
    $location_id: uuid!,
    $occurrences: [events_event_occurrences_insert_input!]!
  ) {
    insert_events_events_one(object: {
      event_id: $event_id,
      event_name: $event_name,
      event_category: $event_category,
      event_image_url: $event_image_url,
      external_link: $external_link,
      location_id: $location_id,
      event_occurrences: {
        data: $occurrences,
        on_conflict: {
          constraint: event_occurrences_pkey,
          update_columns: [start_date, end_date]
        }
      }
    }) {
      event_id
      event_name
      event_category
      event_image_url
      external_link
      created_at
      location: event_location {
        location_id
        location_name
        location_point
      }
      occurrences: event_occurrences(order_by: {start_date: asc}) {
        occurrence_id
        start_date
        end_date
      }
    }
  }
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($event_id: uuid!) {
    delete_events_events_by_pk(event_id: $event_id) {
      event_id
    }
  }
`;

export const GET_ONE_EVENT = gql`
  query GetOneEvent($event_id: uuid!) {
    events_events_by_pk(event_id: $event_id) {
      event_id
      event_name
      event_category
      event_image_url
      external_link
      created_at
      location: event_location {
        location_id
        location_name
        location_point
      }
      occurrences: event_occurrences(order_by: {start_date: asc}) {
        occurrence_id
        start_date
        end_date
      }
    }
  }
`;

export const GET_ONE_LOCATION = gql`
  query GetOneLocation($location_id: uuid!) {
    events_event_locations_by_pk(location_id: $location_id) {
      location_id
      location_name
      location_point
    }
  }
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent(
    $event_id: uuid!,
    $event_name: String!,
    $event_category: events_event_categories_enum!,
    $event_image_url: String!,
    $external_link: String,
    $location_id: uuid!
  ) {
    update_events_events_by_pk(
      pk_columns: { event_id: $event_id },
      _set: {
        event_name: $event_name,
        event_category: $event_category,
        event_image_url: $event_image_url,
        external_link: $external_link,
        location_id: $location_id
      }
    ) {
      event_id
      event_name
      event_category
      event_image_url
      external_link
      created_at
      location: event_location {
        location_id
        location_name
        location_point
      }
    }
  }
`;

export const UPDATE_OCCURRENCES_MUTATION = gql`
  mutation UpdateEventOccurrences(
    $event_id: uuid!,
    $occurrences: [events_event_occurrences_insert_input!]!
  ) {
    delete_events_event_occurrences(
      where: { event_id: { _eq: $event_id } }
    ) {
      affected_rows
    }
    insert_events_event_occurrences(
      objects: $occurrences,
      on_conflict: {
        constraint: event_occurrences_pkey,
        update_columns: [event_id, start_date, end_date]
      }
    ) {
      returning {
        occurrence_id
        start_date
        end_date
      }
    }
  }
`;